<template>
  <div>
    <my-form :start-in-edit-mode="true" success-message="We have received your feedback. Thank you!" :readonly="loading">
      <template v-slot:default="{invalid, valid, errors, formIsReadonly }">
        <spinner :show="loading" />
        <template v-show="!loading">
          <h2 class="text-center">{{title}}</h2>
          <p class="text-center">
            {{cleaner}} {{start}} {{property}}
          </p>
          <dispute-status v-if="existingDispute" :dispute="dispute" />
          <div>
            <div class="mb-2">
              <h2 class="text-center mb-3">Please provide feedback about the cleaning</h2>
              <my-text-area v-model="feedback" name="Feedback" rules="required|min:10"></my-text-area>
            </div>
            <div class="mb-2">
              <h2 class="text-center">Upload pictures below if you have any</h2>
              <p class="text-center">Max. {{ maxImages }} images</p>
              <image-uploader
                :attr="attrForField"
                :maxFiles="maxImages"
                :showResult="true"
                :url="url"
                :process-result="processUpload"
                :form-data="uploadFormData"
                :subscription-path="subscriptionPath"
                @update:loading="loading=$event"
              ></image-uploader>
            </div>
            <div class="mb-2">
              <h2 class="text-center mb-3">How would you like your dispute to be resolved?</h2>
              <dropdown v-model="requestedResolution" :options="options" rules="required"></dropdown>
              <b-form-group v-if="requestedResolution == 'credit'"> </b-form-group>
              <div v-if="requestedResolution == 'other'">
                <my-text v-model="percentage" name="percentage" rules="required" label="Based on your experience what % was not cleaned properly"></my-text>
              </div>
            </div>
          </div>
        </template>
      </template>
      <template v-slot:readonly-buttons></template>
      <template v-slot:edit-buttons="{ invalid, validated }">
        <div v-if="!loading" class="mb-2">
          <div class="text-center">
            <b-button :disabled="invalid && validated" type="submit" variant="primary">Create Dispute</b-button>
          </div>
        </div>
      </template>
    </my-form>
  </div>
</template>
<script>
import CommentField from '@/shared/inputs/text_area.vue'
import MyForm from '@/shared/base_form.vue'
import {
  dateToAppFormat,
  isClient
} from '@/utils'
import api from '@/clients/api'
import Spinner from '@/shared/spinner.vue'
import DisputeStatus from './status.vue'
import ImageUploader from '@/shared/uploaders/image.vue'
import MyTextArea from '@/shared/inputs/text_area.vue'
import MyText from '@/shared/inputs/text.vue'
import Dropdown from '@/shared/inputs/select.vue'
export default {
  props: {
    clientId: {
      type: Number,
      required: true
    },
    jobId: {
      type: Number,
      required: true
    },
    onLightTheme: {
      type: Boolean,
      default: true
    },
  },
  components: {
    MyForm,
    MyText,
    MyTextArea,
    DisputeStatus,
    Dropdown,
    ImageUploader,
    Spinner
  },
  data() {
    return {
      loading: false,
      errors: null,
      cleaner: null,
      start: null,
      hasRating: false,
      hasDispute: false,
      property: null,
      maxImages: 10,
      options: [{
        value: '',
        label: "Please select an option"
      },
      {
        value: "credit",
        label: "I would like a cleaning credit for a future cleaning",
      }, {
        value: "other",
        label: "I would like a different solution",
      }, ],
      percentage: '',
      feedback: '',
      imageIds: [],
      requestedResolution: null,

      minDate: new Date(),
      newStart: null,
      showModal: false,
      startPicked: null
    }
  },
  computed: {
    attrForField() {
      return {
        name: 'dispute_image[image]',
        key: 'id'
      }
    },
    btnVariant() {
      if (this.onLightTheme) {
        return 'light'
      }
      return 'secondary'
    },
    existingDispute() {
      return false
    },
    feedbackState() {
      return this.feedback.length > 5;
    },
    subscriptionPath() {
      return '/cleaning_jobs/' + this.jobId + '/uploads'
    },
    uploadFormData() {
      return { client_id: this.clientId, cleaning_job_id: this.jobId }
    },
    params() {
      var p = {
        client_id: this.clientId,
        dispute: {
          status: 'new',
          cleaning_job_id: this.jobId,
          feedback: this.feedback,
        }
      }
      if (this.imageIds.length > 0) {
        p.dispute.image_ids = this.imageIds
      }
      if (this.requestedResolution == 'credit') {
        p.dispute.give_credits = true
      }
      if (this.percentage != '') {
        p.dispute.percentage = this.percentage
      }
      return p
    },
    url() {
      return "/api/dispute_images"
    },
    title() {
      return 'New dispute for visit'
    }
  },
  methods: {
    onStartPicked(formattedStart, start, status) {
      this.startPicked = formattedStart
      this.newStart = start
      this.status = status
      this.showModal = false
    },
    onToggleModal() {
      this.showModal = !this.showModal
    },
    processUpload(result, key) {
      this.imageIds.push(result[key])
      return result
    },
    submit() {
      return new Promise((resolve, reject) => {
        this.loading = true
        api.startDispute(this.params).then(() => {
          this.loading = false
          this.$emit('update')
          resolve()
        }).catch((error) => {
          this.loading = false
          this.$flasher.error(error.response.data.error)
        })
      })
    },
  },
  mounted() {
    this.loading = true
    api.getMainCleaningJob(this.jobId).then((response) => {
      this.cleaner = response.data.cleaner
      this.start = response.data.start
      this.property = response.data.property
      this.hasRating = response.data.has_rating
      this.hasDispute = response.data.has_dispute
      this.loading = false
    })
  }
}
</script>
